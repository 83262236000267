import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/png/logo-two.png"
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);


const BlogDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">
                 ShareMaster
                </Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>Jan 17, 2024
                  </Text>
                  <Text>Michael Tippett
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-4">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                {/* <!-- Blog section --> */}
                <Post>
                  <div>
                    <img src={titleImage} alt="ShareMaster" className={styles.titleImage} />
                  </div>
                  <div>
    <h1>Introducing <a href="https://sharemaster.io">ShareMaster</a>: The Ultimate SharePoint Migration and Management Tool</h1>
    <p>In the dynamic world of digital workspaces, the ability to efficiently manage and migrate content is crucial for organizations. That's where <a href="https://sharemaster.io">ShareMaster</a> comes into the picture. Launched in 2024, ShareMaster is not just another tool in the arsenal of IT professionals and SharePoint administrators; it is a game-changer in SharePoint migration and management.</p>

    <h2>Revolutionizing SharePoint Online Site Migration</h2>
    <p><a href="https://sharemaster.io">ShareMaster</a> has been meticulously designed to simplify the migration process of SharePoint online sites. Its intuitive interface and robust functionalities make it easy for users to move content, sites, and workflows from one SharePoint environment to another seamlessly. Whether you are upgrading to a newer version of SharePoint or moving to a cloud-based environment, ShareMaster ensures a smooth transition with minimal disruption to your operations.</p>

    <h2>Enhancing the SharePoint Experience</h2>
    <p>But <a href="https://sharemaster.io">ShareMaster's</a> capabilities extend far beyond migration. It brings a suite of features that transform how you interact with SharePoint:</p>
    
    <h3>Streamlined Recycling Bin Management</h3>
    <p>Gone are the days of sifting through a cluttered recycling bin. <a href="https://sharemaster.io">ShareMaster's</a> advanced recycling bin management feature allows users to quickly locate and restore accidentally deleted items. This not only saves time but also ensures that critical data is never lost in the maze of SharePoint's recycling system.</p>

    <h3>Exploring Hidden SharePoint Items and Objects</h3>
    <p>A unique feature of <a href="https://sharemaster.io">ShareMaster</a> is its ability to delve into the hidden aspects of SharePoint. This functionality is a boon for debugging and troubleshooting, as it enables users to explore hidden SharePoint items and objects easily. It empowers administrators and developers to gain insights into their SharePoint environment like never before, leading to more efficient problem-solving and system optimization.</p>

    <h3>Comprehensive Management Solutions</h3>
    <p><a href="https://sharemaster.io">ShareMaster</a> offers a wide range of management solutions that cater to various aspects of SharePoint administration. From user permissions and security settings to workflow management and content organization, ShareMaster provides a centralized platform to manage it all with ease and precision.</p>

    <h2>Why Choose ShareMaster?</h2>
    <ul>
        <li><strong>Ease of Use</strong>: Its user-friendly interface ensures that even those with minimal technical expertise can navigate and utilize its features effectively.</li>
        <li><strong>Time Efficiency</strong>: With ShareMaster, tasks that used to take hours can now be completed in minutes, significantly boosting productivity.</li>
        <li><strong>Enhanced Security</strong>: It offers robust security features, ensuring that your data remains safe during and after the migration process.</li>        
        <li><strong>Comprehensive Support</strong>: <a href="https://sharemaster.io">ShareMaster</a> comes with dedicated customer support to assist with any challenges users may face.</li>
    </ul>

    <p>In conclusion, <a href="https://sharemaster.io">ShareMaster</a> is more than just a migration tool; it's a comprehensive solution for managing SharePoint environments. Its launch in 2024 marks a significant milestone in the evolution of SharePoint management tools. If you're looking to streamline your SharePoint migration and management processes, ShareMaster is the tool you need.</p>

<h2>Ready to Elevate Your SharePoint Experience?</h2>
<p>Take the first step towards a more efficient and organized SharePoint environment with <a href="https://sharemaster.io">ShareMaster</a>. Check out our promotional video and see for yourself how ShareMaster can transform your SharePoint experience. Welcome to the future of SharePoint management!</p>
</div>
                </Post>
                <Box className="d-flex" mt={4}>
                  <BadgePost>Development Product</BadgePost>
                  <BadgePost>SharePoint</BadgePost>
                  <BadgePost>Document Managmenet</BadgePost>

                </Box>

              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogDetails;
